<template>
    <div class="conteudoBloco">
        <a class="ativo" id="toogle" @click="toggleGrafico()" onclick="this.classList.toggle('ativo');return false">
            <span class="toggle"></span>&nbsp;Produção Diária
        </a>

        <div v-if="mostrarGrafico">
            <v-menu location="end">
                <template v-slot:activator="{ props }">
                    <i v-bind="props" class="bi bi-info-circle"></i>
                </template>

                <v-card min-width="100" style="max-width: 23rem; padding: 1rem">
                    <div>
                        <b>Fórmula:</b> Quantidade de produtos acabados (excluindo o tipo 'conjunto') e
                        etapa = disponível / 22. <br>
                        <b>Polaridade:</b> Quanto maior, melhor. <br>
                        <b>Fonte:</b> OMIE > Produção > Produto acabado. <br>
                        <b>Descrição:</b> Quantidade diária de produtos acabados, excluindo o tipo
                        'conjunto'.<br>

                    </div>
                </v-card>
            </v-menu>
            <div v-if="data">
                <div>
                    <Bar :options="dadosGrafico().options" :data="dadosGrafico().data" ref="barChart"
                        style="width: 100%; height: 200px" />
                </div>
                <div style="max-width: fit-content;">
                    <label>Selecione a familia do produto</label>
                    <select v-model="familiaSelecionada" @change="getDados()">
                        <option>Todos</option>
                        <option v-for="i, index in listaFamilias" :key="index">{{ i }}</option>
                    </select>
                </div>
            </div>
            <div class="loading" v-else>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
import serviceGraficos from '@/services/serviceGraficos.js';
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js'
import { getRelativePosition } from 'chart.js/helpers';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement)

export default {
    name: 'ProduçãoDiaria',
    props: {
        mes: { Required: true },
        ano: { Required: true }
    },
    components: {
        Bar,
    },
    data() {
        return {
            data: null,
            mostrarGrafico: true,
            listaFamilias: [],
            familiaSelecionada: 'Todos',
        }
    },
    watch: {
        mes: "getDados",
        ano: "getDados"
    },
    mounted() {
        this.getFamilias();
    },
    methods: {
        async getFamilias() {
            try {
                this.listaFamilias = await serviceGraficos.getFamilias()
                this.getDados()
            } catch (error) {
                console.error('Erro ao obter familias:', error);
            }
        },
        async getDados() {
            try {
                this.data = await serviceGraficos.getProdução(this.mes, this.ano, this.familiaSelecionada);
            } catch (error) {
                console.error('Erro ao obter dados:', error);
            }
        },
        dadosGrafico() {
            var corFonte = null
            var corFonteFraca = null

            if (document.documentElement.classList.contains('dark')) {
                corFonte = 'rgba(255, 255, 255,.85)'
                corFonteFraca = 'rgba(255, 255, 255,.15)'
            } else {
                corFonte = 'rgba(0, 0, 0,.85)'
                corFonteFraca = 'rgba(0, 0, 0,.15)'
            }

            var chartData = {
                datasets: [{
                    label: 'Total',
                    hidden: true,
                    backgroundColor: '#d50000',
                    data: this.data.total
                },
                {
                    label: 'Produção Diária',
                    backgroundColor: '#fc4b2a',
                    data: this.data.mediaDia
                }]
            }

            var chartOptions = {
                responsive: true,
                interaction: {
                    intersect: false,
                    mode: 'nearest',
                },
                scales: {
                    x: {
                        title: {
                            display: false
                        },
                        ticks: {
                            color: corFonte
                        }
                    },
                    y: {
                        ticks: {
                            color: corFonte
                        },
                        grid: {
                            color: corFonteFraca
                        },
                    }
                },
                plugins: {
                    legend: {
                        display: true,
                        labels: {
                            boxWidth: 15,
                            boxHeight: 15,
                            color: corFonte,
                            font: {
                                size: 20,
                            }
                        }
                    }
                },
                onClick: (e) => {
                    const canvasPosition = getRelativePosition(e, this.$refs.barChart.chart);
                    const dataX = this.$refs.barChart.chart.scales.x.getValueForPixel(canvasPosition.x);

                    if (this.mes == null) {
                        this.$emit('updateMes', dataX + 1)
                    }
                }
            }

            return { data: chartData, options: chartOptions }
        },
        toggleGrafico() {
            this.mostrarGrafico = !this.mostrarGrafico;
        },
    },
}
</script>

<style scoped>
.conteudoBloco {
    display: grid;
    gap: 1rem;
    padding: 1rem;
}
</style>