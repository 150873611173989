<template>
    <div class="conteudoBloco">
        <a class="" id="toogle" @click="toggleGrafico()" onclick="this.classList.toggle('ativo');return false">
            <span class="toggle"></span>&nbsp;Clientes Conquistados
        </a>

        <div v-if="mostrarGrafico">
            <v-menu location="end">
                <template v-slot:activator="{ props }">
                    <i v-bind="props" class="bi bi-info-circle"></i>
                </template>

                <v-card min-width="100" style="max-width: 23rem; padding: 1rem">
                    <div>
                        <b>Fórmula:</b> Soma das oportunidades quando o motivo é igual a "oportunidade
                        conquistada" e o tipo corresponde a "Cliente novo", "Cliente reciclado" e
                        "Cliente
                        recorrente".<br>
                        <b>Polaridade:</b> Quanto maior, melhor. <br>
                        <b>Fonte:</b> OMIE > Oportunidade quando o tipo é igual a Cliente novo, Cliente
                        reciclado e Cliente recorrente. <br>
                        <b>Descrição:</b> Quantidade de oportunidades conquistadas classificadas por
                        tipo de
                        Proposta Comercial.<br>
                    </div>
                </v-card>
            </v-menu>
            <div v-if="data">
                <Bar :options="dadosGrafico().options" :data="dadosGrafico().data" ref="barChart"
                    style="width: 100%; height: 200px" />
            </div>
            <div class="loading" v-else>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
import serviceGraficos from '@/services/serviceGraficos.js';
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { getRelativePosition } from 'chart.js/helpers';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'GraficoClientesConquistados',
    props: {
        mes: { Required: true },
        ano: { Required: true }
    },
    components: {
        Bar,
    },
    data() {
        return {
            data: null,
            mostrarGrafico: false,
            lista: null,
            showModal: false,
        }
    },
    watch: {
        mes: "getDados",
        ano: "getDados"
    },
    mounted() {
        this.getDados();
    },
    methods: {
        async getDados() {
            this.data = await serviceGraficos.getClientes(this.mes, this.ano);
        },
        dadosGrafico() {
            var corFonte = null
            var corFonteFraca = null

            if (document.documentElement.classList.contains('dark')) {
                corFonte = 'rgba(255, 255, 255,.85)'
                corFonteFraca = 'rgba(255, 255, 255,.15)'
            } else {
                corFonte = 'rgba(0, 0, 0,.85)'
                corFonteFraca = 'rgba(0, 0, 0,.15)'
            }

            var chartData = {
                datasets: [{
                    label: 'Clientes Novos',
                    backgroundColor: '#3571CD',
                    data: this.data.cliente_novo
                },
                {
                    label: 'Clientes Recorrentes',
                    backgroundColor: '#1d467b',
                    data: this.data.cliente_recorrente
                },
                {
                    label: 'Clientes Reciclados',
                    backgroundColor: '#00b4fc',
                    data: this.data.cliente_reciclado
                }]
            }

            var chartOptions = {
                responsive: true,
                interaction: {
                    intersect: false,
                    mode: 'nearest',
                },
                scales: {
                    x: {
                        title: {
                            display: false
                        },
                        ticks: {
                            color: corFonte
                        }
                    },
                    y: {
                        ticks: {
                            color: corFonte
                        },
                        grid: {
                            color: corFonteFraca
                        },
                    }
                },
                plugins: {
                    legend: {
                        display: true,
                        labels: {
                            boxWidth: 15,
                            boxHeight: 15,
                            color: corFonte,
                            font: {
                                size: 20,
                            }
                        }
                    }
                },
                onClick: (e) => {
                    const canvasPosition = getRelativePosition(e, this.$refs.barChart.chart);
                    const dataX = this.$refs.barChart.chart.scales.x.getValueForPixel(canvasPosition.x);

                    if (this.mes == null) {
                        this.$emit('updateMes', dataX + 1)
                    }
                    // else {
                    //     this.mostrarLista(this.data[dataX].x.slice(0, 2))
                    // }
                }
            }

            return { data: chartData, options: chartOptions }
        },
        toggleGrafico() {
            this.mostrarGrafico = !this.mostrarGrafico;
        },
    },
}
</script>

<style scoped>
.conteudoBloco {
    display: grid;
    gap: 1rem;
    padding: 1rem;
}
</style>