import { api } from "roboflex-thalamus-request-handler";

const MESES = [
    { id: 1, nome: 'Janeiro' },
    { id: 2, nome: 'Fevereiro' },
    { id: 3, nome: 'Março' },
    { id: 4, nome: 'Abril' },
    { id: 5, nome: 'Maio' },
    { id: 6, nome: 'Junho' },
    { id: 7, nome: 'Julho' },
    { id: 8, nome: 'Agosto' },
    { id: 9, nome: 'Setembro' },
    { id: 10, nome: 'Outubro' },
    { id: 11, nome: 'Novembro' },
    { id: 12, nome: 'Dezembro' },
];

const mapByMonth = (data, key) =>
    data.map(item => ({
        x: `${MESES[item.mes - 1]?.nome || 'Desconhecido'}`,
        y: item[key],
    }));

const mapByWeek = (data, key, ano) =>
    data.map(item => ({
        x: `${item.semana.toString().slice(-2)}º Semana de ${ano}`,
        y: item[key],
    }));

const functions = {
    getMesEano() {
        const anoAtual = new Date().getFullYear();
        const anos = Array.from({ length: anoAtual - 2023 + 1 }, (_, index) => anoAtual - index);
        return { meses: MESES, anos };
    },

    async getPropostasComerciais(mes, ano) {
        const endpoint = mes
            ? '/omie/oportunidade/proposta-viabilizada'
            : '/omie/oportunidade/proposta-viabilizada-mes';

        const params = mes ? { mes, ano } : { ano };
        const response = await api.post(endpoint, params);

        return mes
            ? mapByWeek(response.data, 'regSemana', ano)
            : mapByMonth(response.data, 'valor');
    },

    async getListaPropostasComerciais(anoSemana) {
        const response = await api.post('/omie/oportunidade/proposta-viabilizada/detalhe', { anoSemana });
        return response.data.sort((a, b) => new Date(a.dConclusao) - new Date(b.dConclusao));
    },

    async getFamilias() {
        const response = await api.get('/buscar/familia-produto-vendido');
        return response.data.map(item =>
            item.familia_nome.charAt(0).toUpperCase() + item.familia_nome.slice(1).toLowerCase()
        );
    },

    async getProdutosVendidos(familia, mes, ano) {
        const endpoint = mes
            ? '/indicador/produto-vendido'
            : '/indicador/produto-vendido-mes';

        const params = mes
            ? { nome: familia, ano, mes }
            : { nome: familia, ano };

        const response = await api.post(endpoint, params);

        return mes
            ? mapByWeek(response.data, 'regSemana', ano)
            : mapByMonth(response.data, 'regMes');
    },

    async getClientes(mes, ano) {
        const endpoint = '/omie/oportunidade/cliente-alcancado';
        const params = mes ? { ano, mes } : { ano };
        const response = await api.post(endpoint, params);

        const keys = ['cliente_novo', 'cliente_recorrente', 'cliente_reciclado'];
        const mapFunction = mes ? mapByWeek : mapByMonth;

        return keys.reduce((acc, key) => {
            acc[key] = mapFunction(response.data, key, ano);
            return acc;
        }, {});
    },

    async getSaldoFinanceiro(mes, ano) {
        const endpoint = mes
            ? '/indicador/contas/pagar-receber'
            : '/indicador/contas/pagar-receber/ano';
        const params = mes ? { ano, mes } : { ano };
        const response = await api.post(endpoint, params);

        const keys = mes
            ? ['valorSemanaReceber', 'valorSemanaPagar']
            : ['valorMesReceber', 'valorMesPagar'];

        const mapFunction = mes ? mapByWeek : mapByMonth;

        const mappedData = keys.reduce((acc, key) => {
            acc[key] = mapFunction(response.data, key, ano);
            return acc;
        }, {});

        const saldo = mappedData[keys[0]].map((item, index) => {
            const valorReceber = item.y;
            const valorPagar = mappedData[keys[1]][index]?.y || 0;
            const saldoCalculado = valorReceber - valorPagar;

            return {
                x: item.x,
                y: saldoCalculado
            };
        });

        return {
            [keys[0]]: mappedData[keys[0]],
            [keys[1]]: mappedData[keys[1]],
            saldo
        };
    },

    async getDetalhesSaldo(semana) {
        const response = await api.post('/indicador/contas/semana-detalhe', {
            anoSemana: semana,
        })
        return response.data
    },

    async getProdução(mes, ano, produto) {
        const endpoint = mes
            ? '/indicador/produto-produzido'
            : '/indicador/produto-produzido';

        const params = mes
            ? { produto, ano, mes }
            : { produto, ano };

        const response = await api.post(endpoint, params);

        const keys = ['total', 'mediaDia'];
        const mapFunction = mes ? mapByWeek : mapByMonth;

        return keys.reduce((acc, key) => {
            acc[key] = mapFunction(response.data, key, ano);
            return acc;
        }, {});
    }

};

export default functions;
