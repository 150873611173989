<template>
    <div class="SeleçãoMeses">
        <div class="tags">
            <a title="Ano inteiro!" :class="mes == null ? 'ativo' : ''" @click="$emit('updateMes', null)">
                <i class="bi bi-calendar-minus"></i>
            </a>
            <a v-for="i in mesesEanos.meses" :key="i" :id="i.id" :class="mes == i.id ? 'ativo' : ''"
                @click="$emit('updateMes', i.id)">
                {{ i.nome }}
            </a>
            <select v-model="selectedAno" @change="$emit('updateAno', selectedAno)">
                <option v-for="ano, index in mesesEanos.anos" :key="index">{{ ano }}</option>
            </select>
        </div>
    </div>
</template>

<script>
import serviceGraficos from '@/services/serviceGraficos.js';

export default {
    props: {
        mes: {
            type: [Number, null],
            default: null
        },
        ano: {
            type: Number,
            default: new Date().getFullYear()
        }
    },
    data() {
        return {
            mesesEanos: serviceGraficos.getMesEano(),
            selectedAno: this.ano
        };
    }
};
</script>

<style scoped>
.SeleçãoMeses {
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    border: 1px solid var(--cor-separador);
    z-index: 9998;
    background-color: var(--cor-bg);

    a {
        cursor: pointer;
    }

    select {
        width: 6rem;
        border-radius: 25px;
    }
}
</style>
