<template>
    <div class="modal-mask" @click="fecharModal">
        <div style="max-width: 60vw; max-height: 80svh; overflow-y: auto;" class="jm margem" @click.stop>
            <div class="alinha-centro">
                <h2>Transações da {{ `${semanaAno.slice(-2)}º semana de ${semanaAno.slice(0, 4)}` }}</h2>
            </div>
            <div style="display: flex; flex-flow: column; font-size: larger;">
                <div>
                    Total Recebido: {{ lista.valorSemanaReceber.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    }) }}
                </div>
                <div>
                    Total Pago: {{ lista.valorSemanaPagar.toLocaleString('pt-BR', {
                        style: 'currency', currency:
                            'BRL'
                    }) }}
                </div>
                <div>
                    Saldo da semana: {{ (lista.valorSemanaReceber -
                        lista.valorSemanaPagar).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
                </div>
            </div>
            <br>
            <div class="grid-2">
                <div>
                    <label class="alinha-centro">
                        <h3>Recebidos</h3>
                    </label>
                    <table class="tabela">
                        <tr>
                            <th>
                                Valor
                            </th>
                            <th>
                                Nome
                            </th>
                        </tr>
                        <tr v-for="i, index in listaOrdenada.statusReceber" :key="index">
                            <td class="txt-sucesso">{{ i.valor.toLocaleString('pt-BR', {
                                style: 'currency', currency:
                                    'BRL'
                            }) }}</td>
                            <td>{{ i.nome }}</td>
                        </tr>
                    </table>
                </div>
                <div>
                    <label class="alinha-centro">
                        <h3>Pagos</h3>
                    </label>
                    <table class="tabela">
                        <tr>
                            <th>
                                Valor
                            </th>
                            <th>
                                Nome

                            </th>
                        </tr>
                        <tr v-for="i, index in listaOrdenada.statusPagar" :key="index">
                            <td class="txt-erro">{{ i.valor.toLocaleString('pt-BR', {
                                style: 'currency', currency:
                                    'BRL'
                            }) }}</td>
                            <td>{{ i.nome }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalDetalhesSaldo',
    props: {
        lista: {
            required: true
        },
        semanaAno: {
            required: true
        }
    },
    data() {
        return {
            ordenacao: {
                coluna: 'valor',
                ordem: 'desc',
                tabela: null
            },
            listaOrdenada: {}
        };
    },
    methods: {
        formatarData(data) {
            const date = new Date(data);
            return date.toLocaleDateString('pt-BR');
        },
        fecharModal() {
            this.$emit('fecharModal');
        },
        ordenar() {
            this.listaOrdenada = {
                statusReceber: [...this.lista.statusReceber].sort((a, b) => b.valor - a.valor),
                statusPagar: [...this.lista.statusPagar].sort((a, b) => b.valor - a.valor)
            };
        }
    },
    mounted() {
        this.ordenar();
    },
};
</script>
