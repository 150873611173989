<template>
    <div class="modal-mask" @click="fecharModal">
        <div style="max-width: 60vw" class="jm margem">
            <h2 style="text-align: center;">
                Propostas Viabilizadas
            </h2>
            <ul style="list-style: decimal; font-size: larger;">
                <li v-for="item, index in lista" :key="index">
                    <span>Fechado em {{ formatarData(item.dConclusao.slice(0, 10)) }} Para {{
                        item.cDesOp }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalPropostaComerciais',
    props: {
        lista: {
            Required: true
        }
    },
    methods: {
        formatarData(data) {
            const date = new Date(data);
            return date.toLocaleDateString('pt-BR');
        },
        fecharModal() {
            this.$emit('fecharModal')
        }
    },
}
</script>