<template>
    <HeaderSelecaoMeses :mes="mes" :ano="ano" @updateMes="atualizarMes" @updateAno="atualizarAno" />
    <div class="margem">
        <BlocoComercial :mes="mes" :ano="ano" @updateMes="atualizarMes" />
        <BlocoFinanceiro :mes="mes" :ano="ano" @updateMes="atualizarMes" />
        <BlocoProdução :mes="mes" :ano="ano" @updateMes="atualizarMes" />
    </div>
</template>

<script>
import BlocoComercial from '@/components/BlocoComercial.vue';
import BlocoFinanceiro from '@/components/BlocoFinanceiro.vue';
import BlocoProdução from '@/components/BlocoProdução.vue';
import HeaderSelecaoMeses from '@/components/HeaderSelecaoMeses.vue';

export default {
    components: {
        HeaderSelecaoMeses,
        BlocoComercial,
        BlocoFinanceiro,
        BlocoProdução
    },
    data() {
        return {
            mes: null,
            ano: new Date().getFullYear(),
            showModal: false,
            // ano: 2023,
        };
    },
    methods: {
        atualizarMes(novoMes) {
            this.mes = novoMes;
        },
        atualizarAno(novoAno) {
            this.ano = novoAno;
        },
    },
};
</script>
<style></style>
