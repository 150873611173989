<template>
    <div class="bloco">
        <div @click="toggleexibirConteudo" class="tituloBloco bg-erro">
            <strong>
                <i id="iconeComercial" :class="iconeComercial"></i> Produção
            </strong>
        </div>
        <div v-if="exibirConteudo" >
            <ProduçãoDiaria @updateMes="atualizarMes" :mes="mes" :ano="ano"  />
        </div>
    </div>
</template>

<script>
import ProduçãoDiaria from './Graficos/Produção/ProduçãoDiaria.vue';

export default {
    name: 'BlocoProdução',
    props: {
        mes: { Required: true },
        ano: { Required: true }
    },
    components: {
        ProduçãoDiaria,
    },
    computed: {
        iconeComercial() {
            return this.exibirConteudo ? 'bi bi-caret-down-fill' : 'bi bi-caret-right-fill';
        },
    },
    data() {
        return {
            exibirConteudo: false,
        }
    },
    methods: {
        toggleexibirConteudo() {
            this.exibirConteudo = !this.exibirConteudo;
        },
        atualizarMes(mes) {
            this.$emit('updateMes', mes)
        }
    }
}
</script>

<style scoped>
.tituloBloco {
    display: flex;
    align-items: center;
    padding: 1rem;
    font-size: 1.2rem;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
</style>