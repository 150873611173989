<template>
    <div class="conteudoBloco">
        <a class="ativo" id="toogle" @click="toggleGrafico()" onclick="this.classList.toggle('ativo');return false">
            <span class="toggle"></span>&nbsp;Propostas Comerciais
        </a>

        <div v-if="mostrarGrafico">
            <v-menu location="end">
                <template v-slot:activator="{ props }">
                    <i v-bind="props" class="bi bi-info-circle"></i>
                </template>

                <v-card min-width="100" style="max-width: 23rem; padding: 1rem">
                    <div>
                        <b>Fórmula:</b> Soma das oportunidades no status "conquistado" por mês. <br>
                        <b>Polaridade:</b> Quanto maior, melhor. <br>
                        <b>Fonte:</b> OMIE > CRM > Oportunidades. <br>
                        <b>Descrição:</b> Quantidade de oportunidades que foram conquistadas em
                        determinado
                        mês.<br>
                    </div>
                </v-card>
            </v-menu>
            <div>
                <Bar :options="dadosGrafico().options" :data="dadosGrafico().data" ref="barChart"
                    style="width: 100%; height: 200px" />
            </div>
        </div>
    </div>
    <ModalListaPropostaComerciais :lista="lista" v-if="showModal" @fecharModal="showModal = false" />
</template>

<script>
import serviceGraficos from '@/services/serviceGraficos.js';
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { getRelativePosition } from 'chart.js/helpers';
import ModalListaPropostaComerciais from '@/components/Modais/ModalListaPropostaComerciais.vue';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'GraficoPropostasComerciais',
    props: {
        mes: { Required: true },
        ano: { Required: true }
    },
    components: {
        ModalListaPropostaComerciais,
        Bar,
    },
    data() {
        return {
            data: [],
            mostrarGrafico: true,
            lista: null,
            showModal: false
        }
    },
    watch: {
        mes: "getDados",
        ano: "getDados"
    },
    mounted() {
        this.getDados();
    },
    methods: {
        async getDados() {
            try {
                this.data = await serviceGraficos.getPropostasComerciais(this.mes, this.ano);
            } catch (error) {
                console.error('Erro ao obter dados:', error);
            }
        },
        async mostrarLista(semana) {
            this.lista = await serviceGraficos.getListaPropostasComerciais(this.ano + semana)
            this.showModal = true
        },
        dadosGrafico() {
            var corFonte = null
            var corFonteFraca = null

            if (document.documentElement.classList.contains('dark')) {
                corFonte = 'rgba(255, 255, 255,.85)'
                corFonteFraca = 'rgba(255, 255, 255,.15)'
            } else {
                corFonte = 'rgba(0, 0, 0,.85)'
                corFonteFraca = 'rgba(0, 0, 0,.15)'
            }

            var chartData = {
                datasets: [{
                    label: 'Propostas Comerciais Viabilizadas',
                    backgroundColor: '#3571CD',
                    data: this.data
                }]
            }

            var chartOptions = {
                responsive: true,
                interaction: {
                    intersect: false,
                    mode: 'nearest',
                },
                scales: {
                    x: {
                        title: {
                            display: false
                        },
                        ticks: {
                            color: corFonte
                        }
                    },
                    y: {
                        ticks: {
                            color: corFonte
                        },
                        grid: {
                            color: corFonteFraca
                        },
                    }
                },
                plugins: {
                    legend: {
                        display: true,
                        labels: {
                            boxWidth: 15,
                            boxHeight: 15,
                            color: corFonte,
                            font: {
                                size: 20,
                            }
                        }
                    }
                },
                onClick: (e) => {
                    const canvasPosition = getRelativePosition(e, this.$refs.barChart.chart);
                    const dataX = this.$refs.barChart.chart.scales.x.getValueForPixel(canvasPosition.x);

                    if (this.mes == null) {
                        this.$emit('updateMes', dataX + 1)
                    }
                    else {
                        this.mostrarLista(this.data[dataX].x.slice(0, 2))
                    }
                }
            }

            return { data: chartData, options: chartOptions }
        },
        toggleGrafico() {
            this.mostrarGrafico = !this.mostrarGrafico;
        },
    },
}
</script>

<style scoped>
.conteudoBloco {
    display: grid;
    gap: 1rem;
    padding: 1rem;
}
</style>