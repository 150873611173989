<template>
    <div class="conteudoBloco">
        <a class="ativo" id="toogle" @click="toggleGrafico()" onclick="this.classList.toggle('ativo');return false">
            <span class="toggle"></span>&nbsp;Pagos e Recebidos
        </a>

        <div v-if="mostrarGrafico">
            <v-menu location="end">
                <template v-slot:activator="{ props }">
                    <i v-bind="props" class="bi bi-info-circle"></i>
                </template>

                <v-card min-width="100" style="max-width: 23rem; padding: 1rem">
                    <div>
                        <b>Fórmula:</b> Somatório dos valores dos processos pagos com os status
                        (Atrasado e
                        Pago), somatório dos valores recebidos com os status (Atrasado, Cancelado e
                        Recebido).<br>
                        <b>Polaridade:</b> Saldo: Quanto maior, melhor.<br>
                        <b>Fonte:</b> OMIE > Finanças > Contas a Pagar/Receber.<br>
                        <b>Descrição:</b> Barra verde: Demonstração do total de contas recebidas no
                        período
                        selecionado.<br>
                        Barra vermelha: Demonstração do total de contas pagas no período
                        selecionado.<br>
                        Linha amarela: Demonstração do saldo (valor a receber - valor a pagar) no
                        período
                        selecionado.
                    </div>
                </v-card>
            </v-menu>
            <div>
                <Bar :options="dadosGrafico().options" :data="dadosGrafico().data" ref="barChart"
                    style="width: 100%; height: 200px" />
            </div>
        </div>
    </div>
    <ModalDetalhesSaldo :lista="lista[0]" :semanaAno="semanaAno" v-if="showModal" @fecharModal="showModal = false" />
</template>

<script>
import serviceGraficos from '@/services/serviceGraficos.js';
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js'
import { getRelativePosition } from 'chart.js/helpers';
import ModalDetalhesSaldo from '@/components/Modais/ModalDetalhesSaldo.vue';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement)

export default {
    name: 'GraficoPagosRecebidos',
    props: {
        mes: { Required: true },
        ano: { Required: true }
    },
    components: {
        Bar,
        ModalDetalhesSaldo
    },
    data() {
        return {
            data: [],
            mostrarGrafico: true,
            showModal: false,
            lista: 'teste',
            semanaAno: null
        }
    },
    watch: {
        mes: "getDados",
        ano: "getDados"
    },
    mounted() {
        this.getDados();
    },
    methods: {
        async getDados() {
            try {
                this.data = await serviceGraficos.getSaldoFinanceiro(this.mes, this.ano);
            } catch (error) {
                console.error('Erro ao obter dados:', error);
            }
        },
        dadosGrafico() {
            var corFonte = null
            var corFonteFraca = null

            if (document.documentElement.classList.contains('dark')) {
                corFonte = 'rgba(255, 255, 255,.85)'
                corFonteFraca = 'rgba(255, 255, 255,.15)'
            } else {
                corFonte = 'rgba(0, 0, 0,.85)'
                corFonteFraca = 'rgba(0, 0, 0,.15)'
            }

            var chartData = {
                datasets: [{
                    label: 'Saldo',
                    type: 'line',
                    backgroundColor: '#E1BB22',
                    borderColor: '#E1BB22',
                    tension: 0.2,
                    data: this.data.saldo
                }, {
                    label: 'Recebidos',
                    backgroundColor: 'rgba(129, 199, 132, 1)',
                    data: this.mes ? this.data.valorSemanaReceber : this.data.valorMesReceber
                },
                {
                    label: 'Pagos',
                    backgroundColor: '#E13417',
                    data: this.mes ? this.data.valorSemanaPagar : this.data.valorMesPagar
                }]
            }

            var chartOptions = {
                responsive: true,
                interaction: {
                    intersect: false,
                    mode: 'nearest',
                },
                scales: {
                    x: {
                        title: {
                            display: false
                        },
                        ticks: {
                            color: corFonte
                        }
                    },
                    y: {
                        ticks: {
                            color: corFonte
                        },
                        grid: {
                            color: corFonteFraca
                        },
                    }
                },
                plugins: {
                    legend: {
                        display: true,
                        labels: {
                            boxWidth: 15,
                            boxHeight: 15,
                            color: corFonte,
                            font: {
                                size: 20,
                            }
                        }
                    }
                },
                onClick: (e) => {
                    const canvasPosition = getRelativePosition(e, this.$refs.barChart.chart);
                    const dataX = this.$refs.barChart.chart.scales.x.getValueForPixel(canvasPosition.x);

                    if (this.mes == null) {
                        this.$emit('updateMes', dataX + 1)
                    }
                    else {
                        this.mostrarLista(this.data.saldo[dataX].x.slice(0, 2))
                    }
                }
            }

            return { data: chartData, options: chartOptions }
        },
        async mostrarLista(semana) {
            this.semanaAno = this.ano + semana
            var response = await serviceGraficos.getDetalhesSaldo(this.ano + semana);
            this.lista = response

            this.showModal = true
        },
        toggleGrafico() {
            this.mostrarGrafico = !this.mostrarGrafico;
        },
    },
}
</script>

<style scoped>
.conteudoBloco {
    display: grid;
    gap: 1rem;
    padding: 1rem;
}
</style>